import {
    HttpClient,
    HttpParams
} from '@angular/common/http';
import {
    Location
} from '@angular/common';
import {
    Injectable
} from '@angular/core';
import {
    AlertController,
    ModalController,
    MenuController,
    ToastController,
    PopoverController,
    Platform
} from '@ionic/angular';
import {
    Observable
} from 'rxjs';
import {
    IwiUtilService
} from './IwiUtilService';
import {
    JwtHelperService
} from '@auth0/angular-jwt';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import _ from 'lodash';
import {
    BehaviorSubject
} from 'rxjs';
import {
    checkAvailability
} from '@ionic-native/core';
import {
    ApperyioHelperService
} from '../apperyio/apperyio_helper';

import * as qz from 'qz-tray';
import {
    sha256
} from 'js-sha256';
import {
    KJUR,
    KEYUTIL,
    stob64,
    hextorstr
} from 'jsrsasign';

//declare var window: any;
/*
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

const jwtHelperService = new JwtHelperService();

@Injectable()
export class IwiService {
    //r - root scope
    public r: any = {
        userData: {}
    };
    //d - dictionary
    public d: any = {
        //feelingsWheel: {}
        uuid: 'test from browser',
        notifMethods: ['EMAIL', 'PUSH'],
        qzPrint: {}
    };
    //LAST PROD RELEASE
    // 20 NOV 2020 #183 // 22 OCT 2020 #173
    public version: string;
    public testMode: boolean;
    public ENV: string;
    public forceENV: boolean;

    public rootScopeInitialVars: any = {};
    public sessionInterval: any;
    public toastFixed: any;

    //public quotesNumber: number = 0;
    public today: Date;
    public todayStr: string;
    public todayCalendar: string;
    public tomorrowCalendar: string;
    public yesterdayCalendar: string;
    public todayStrAPI: string;

    constructor(
        private Apperyio: ApperyioHelperService,
        private location: Location,
        public http: HttpClient,
        public platform: Platform,
        public modalController: ModalController,
        public alertController: AlertController,
        public toastController: ToastController,
        public popoverCtrl: PopoverController,
        public menu: MenuController,
        public iwiu: IwiUtilService,
    ) {
        this.updateToday();
        //this.setupInitialConfiguration();
        // 1.0 31-MAR-2023
        this.version = '1.213'; // 1.01 31-MAR-2023
        this.forceENV = false;
        this.testMode = true;
        this.ENV = 'QA'; // QA or PROD !!!
        // forceENV=true  - will use `testMode` and `API.ENV` from config here
        // forceENV=false - will use `testMode=false` and API.ENV='PROD' running on `iwiingames.com` or device
        //                - will use `testMode=true` and API.ENV='QA' running on `stage.iwiingames.com` or `appery.io`
        //throw new Error('Sentry Test Error');

    }

    async trigger(timeout ? : boolean) {
        // close alert
        try {
            const alertElement = await this.alertController.getTop();
            if (alertElement && timeout !== true) {
                alertElement.dismiss();
            }
        } catch (error) {}

        // close toaster
        try {
            const toastElement = await this.toastController.getTop();
            if (toastElement) {
                toastElement.dismiss();
            }
        } catch (error) {}

        // close popover
        try {
            const element = await this.popoverCtrl.getTop();
            if (element) {
                element.dismiss();
            }
        } catch (error) {}

        // close modal
        try {
            const modalElement = await this.modalController.getTop();
            if (modalElement) {
                modalElement.dismiss();
            }
        } catch (error) {
            console.log(error);

        }

        // close side menua
        try {
            const element = await this.menu.getOpen();
            if (element !== null) {
                this.menu.close();
                return;
            }
        } catch (error) {}
    }

    updateToday() {
        this.today = new Date();
        this.todayStrAPI = this.iwiu.dateToString(this.today);
        this.todayStr = moment(this.today).format('MM-DD-YYYY');
        this.todayCalendar = moment(this.today).format('YYYY-MM-DD');
        this.tomorrowCalendar = moment(this.today).add(1, 'days').format('YYYY-MM-DD');
        this.yesterdayCalendar = moment(this.today).add(-1, 'days').format('YYYY-MM-DD');
    }

    getStartDate(date) {
        return moment(date).format('YYYY-MM-DD') + 'T00:00:00';
    }
    getEndDate(date) {
        return moment(date).format('YYYY-MM-DD') + 'T23:59:59';
    }

    setActivePage(page: string) {
        //console.log('this.activePage changed', page);
        if (page === 'home') {
            //console.log('reget quotes');
        }
    }

    checkRole(...args: any[]) {
        if (!this.r.userData || !this.r.userData.roles) {
            return false;
        }

        for (var a in args) {
            if (this.r.userData.roles.filter((permission) => {
                    return permission.toUpperCase() === args[a].toUpperCase();
                }).length > 0) {
                return true;
            }
        }
        //console.log(this.r.userData.roles, arguments);
        return false;
    }

    checkRoleForUser(user, ...args: any[]) {
        var args1 = arguments;

        if (!user || !user.roles) {
            return false;
        }

        for (var a in args1) {
            if (typeof args[a] === 'string' && user.roles.indexOf(args[a].toUpperCase()) >= 0) {
                return true;
            }
        }
        //console.log(this.r.userData.userRoles, arguments);
        return false;

    }

    checkType(accountType) {
        return this.r.userData ? this.r.userData.type === accountType : false;
    }

    setupInitialConfiguration() {
        var watchId = null;
        var date = new Date();
        date.setDate(date.getDate() - 1);

        const osVer = (this.platform.is('ios')) ? 'ios' : 'android';

        //alert('In init');
        console.log('INIT', 'forceENV = ' + this.forceENV, 'testMode = ' + this.testMode, 'ENV = ' + this.ENV);
        this.rootScopeInitialVars = {
            // 01 MAY 2023 #1
            baseCodeVersion: 1, // last release in Stores == 
            // 01 MAY 2023 #1
            autoUpdateCodeVersion: 1, // last AutoUpdates === 01 MAY 2023 #1
            minorStageRelease: this.version, // update on stage
            upToDate: true,
            upToDateMessage: 'App version not up-to-date, please, press Ctrl+F5 to refresh this page',
            testMode: this.testMode,
            API: {
                ENV: this.ENV, //'QA', // QA or PROD !!!
                URL: 'THIS URL WILL BE UPDATED IN CODE BELOW',
                Headers: {
                    //'Authorization': 'Bearer', 
                    'Accept': 'application/json; charset=utf-8',
                    'Content-Type': 'application/json; charset=utf-8',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                },
                pdfHeaders: {
                    //'Authorization': 'Bearer',
                    //'Accept': 'application/pdf; charset=utf-8',
                    //'Content-Type': 'application/pdf; charset=utf-8'
                },
                sHeaders: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                eventHeaders: {
                    //'Authorization': 'Bearer', 
                },
                lastModified: {},
            },
            sessionRefreshRequired: false,
            UNAPI: {},
            osVer: osVer,
            document: document,
            isCordovaApp: document.URL.indexOf('http://localhost') > -1 || document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1,
            admin: {
                superPass: '142536',
            },
            sessionToken: '',
            loggedin: false,
            userData: {
                activated: false,
                approved: false,
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                sessionToken: '',
                communications: []
            },
            manage: {
                doc: {

                }
            },
            logsBoard: {
                data: [],
                loading: false
            },
            message: {
                push: {}
            },
            eventSource: null,
            activePage: 'home',
            dDateFormat: 'MM-dd-yyyy', //should not used any more
            dDateShortFormat: 'MM/dd/yy', //should not used any more
            dTimeFormat: 'h:mm a',
            dDateTimeFormat: 'MM-dd-yyyy h:mm a',
            dDatePathFormat: 'MM/dd/yyyy',
            dDatePathFormatStr: 'XX/XX/XXXX',
            currentPlan: {},
            management: false,
            info: {
                page: 'IWI'
            },
            notifications: {},
            notesMaxLength: 4000,
            qzPrint: {
                enabled: true
            },
            history: {
                ticket: {
                    api: 'sale',
                    show: true,
                    data: {}
                },
                topup: {
                    api: 'topup',
                    show: true,
                    data: {}
                },
                payout: {
                    api: 'payout',
                    show: true,
                    data: {}
                },
                checkin: {
                    api: 'check-in',
                    show: true,
                    data: {}
                },
            }
        };

        this.initRootScopeVars();

        //this didn't work
        //document.addEventListener("deviceready", this.registerDeviceForPush);
        console.log('before the platform is ready');
        this.platform.ready().then(() => {
            console.log('the platform is ready');
            this.registerDeviceForPush();
            //BACK BUTTON
            this.platform.backButton.subscribeWithPriority(0, async (processNextHandler) => {
                try {
                    const element = await this.modalController.getTop();
                    if (element) {
                        element.dismiss();
                        return;
                    }
                    this.location.back();

                } catch (error) {}
            });
        });

        var userData = localStorage.getItem('iwiUserData');
        //console.log('userData: ', userData);
        this.r.userData = {};
        this.r.loggedin = false;
        if (userData && userData !== null) {
            //display userdata
            this.r.userData = JSON.parse(userData);
            if (this.r.userData.mrn) {
                var notifications = localStorage.getItem('iwi-' + this.r.userData.mrn);
                if (notifications && notifications !== null) {
                    this.r.notifications = JSON.parse(notifications);
                }
            }
            this.r.sessionToken = localStorage.getItem('iwiSessionToken');
            if (!this.r.sessionToken) {
                this.r.userData = {};
                this.r.loggedin = false;
                this.Apperyio.navigateTo("Login", {});
                //this.router.navigateRoot(["Login"]);

            } else {
                this.r.API.Headers.Authorization = 'Bearer ' + (this.r.sessionToken ? '' : this.r.sessionToken);
                this.r.API.eventHeaders.Authorization = this.r.API.Headers.Authorization;
                this.r.API.pdfHeaders.Authorization = this.r.API.Headers.Authorization;
                this.r.loggedin = true;

                document.addEventListener('DOMContentLoaded', (event) => {
                    console.log('DOM fully loaded and parsed');
                    this.getUserData(true, () => {
                        if (this.r.userData.activated) {

                            if (this.checkRole('manager', 'admin')) {
                                this.printConnect();
                            }

                            this.Apperyio.navigateTo("hometabs/Home", {});
                        } else {
                            this.Apperyio.navigateTo("hometabs/Profile", {});
                        }

                    });
                });
                //this.startEventSource(true);
            }
        }
    }

    initRootScopeVars() {
        this.r = _.cloneDeep(this.rootScopeInitialVars);

        if (this.forceENV) {
            console.log('ENV var forced to: ENV=' + this.r.API.ENV + ', testMode=' + this.r.testMode, window.location.host);
        } else {
            if (window.location.host === 'stage.iwiingames.com' || window.location.host === 'appery.io') {
                this.r.testMode = true;
                this.r.API.ENV = 'QA';
            } else {
                this.r.testMode = false;
                this.r.API.ENV = 'PROD';
            }
            console.log('ENV autodetected: ENV=' + this.r.API.ENV + ', testMode=' + this.r.testMode, window.location.host);
        }
        this.r.API.URL = this.r.API.ENV === 'PROD' ? 'https://iwiin.herokuapp.com/' : 'https://iwiin-staging.herokuapp.com/';
        this.r.API.sURL = this.r.API.URL + 'security/';
        //this.r.API.URL += '/api/' + this.r.API.VER + '/';
        this.r.UNAPI.URL = this.r.API.URL.replace('api', 'unapi');


        // custom navigation for non PROD ENV
        if (this.r.API.ENV !== 'PROD') {
            // this.r.admin.reports.shift.selectedMerch = 479;
            // this.r.admin.reports.shift.userData = null;
            // this.r.admin.reports.shift.date = new Date();
            this.Apperyio.navigateTo("Start", {});

        }

        this.r.version = this.r.baseCodeVersion;
        /*if (this.r.isCordovaApp) {
            if (typeof cordova.getAppVersion != 'undefined' && cordova.getAppVersion !== null) {
                cordova.getAppVersion.getVersionCode().then(function(version) {
                    if (version) {
                        this.r.version = version;
                        console.log('Cordova App version detected: ' + version);
                    }
                });
            }
        }*/
        this.r.version += '.' + (this.r.isCordovaApp ? this.r.osVer.substring(0, 1) : 'b') + '.';
        this.r.version += this.r.autoUpdateCodeVersion + '.' + this.r.minorStageRelease;

        //Sentry.setTag('version', this.r.version);

        //console.log(this.r);
    }

    startEventSource(start) {
        //TODO
    }

    logout(timeout ? : boolean): Observable < any > {
        /*if (this.toastFixed !== undefined && this.toastFixed !== null) {
            this.toastFixed.dismiss();
        }*/
        this.trigger(timeout);

        //localStorage.removeItem('iwiUserData');
        localStorage.removeItem('iwiSessionToken');
        //Sentry.configureScope(scope => scope.setUser(null));
        this.r = {};
        this.initRootScopeVars();
        this.startEventSource(false);
        this.Apperyio.navigateTo("Login", {});

        clearInterval(this.sessionInterval);
        //close modal in case we logged out but currently opened any modal
        if (this.r.modalCtrl !== null) {
            //console.log('close modal');
            /*this.r.modalCtrl.dismiss({
                mode: 'close'
            });*/
        }

        return new Observable((observer) => {
            observer.next();
            observer.complete();
        })
    }

    getInfo() {
        this.http
            .get(
                'https://iwiin-staging.herokuapp.com/info',
            )
            .subscribe(
                (data: any) => {
                    console.log('info', data);
                },
                (error: any) => {
                    console.log(error);
                },
                () => {
                    //console.log("final");
                }
            );
    }

    getUserData(showSpinner: boolean, callback ? : any) {

        if (jwtHelperService.isTokenExpired(localStorage.getItem('iwiSessionToken'))) {
            this.logout();
            return;
        }

        var showSpinner = showSpinner === undefined ? true : showSpinner;
        //let's show all the time to hide not actualt state
        //we can't do it - spinner initially not initialized
        //showSpinner = true;
        //console.log('Check User info..');
        showSpinner && this.iwiu.startSpinner('Check User info..');
        this.http
            .get(
                this.r.API.URL + 'account'
            )
            .subscribe(
                (data: any) => {
                    showSpinner && this.iwiu.stopSpinner();
                    if (data) {
                        //console.log('userData', data);
                        data.phone = data.phone.replace(/\s/g, '').replace(/[(-)]/g, '').replace('-', '');
                        this.r.userData = data;
                        //this.r.sessionToken = data.token;
                        this.r.loggedin = true;
                        //this.r.userData.verificationEmail = this.iwiu.getVerificationEmail(this.r.userData);
                        localStorage.setItem('iwiUserData', JSON.stringify(data));

                        /*Sentry.setUser({
                            email: data.username
                        });*/
                        //this.getUsersCommunication();

                        //localStorage.setItem('iwiSessionToken', data.token);
                    } else {
                        this.logout();
                    }
                    //this.quotesNumber = Math.floor(Math.random() * this.quotes.length);
                    //console.log(this.iwiu.quotes.length, this.iwiu.quotesNumber)

                    //throw new Error('Sentry Test Error');
                    callback && callback();
                },
                (error: any) => {
                    showSpinner && this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Get user data failed', error);
                    if (error.status === 404) {
                        this.Apperyio.navigateTo("Login", {});
                    }
                    //this.error = error.error? error.error.message : 'Login failed. Please, check your credentials and try again.';
                    //console.log(error);
                },
                () => {
                    //console.log("final");
                }
            );
    }

    //refresh user's token in case it soon expired
    refreshToken() {
        const now = new Date();
        const tokenExpirationDate = this.getTokenExpirationDate();
        //console.log(tokenExpirationDate)
        if (tokenExpirationDate !== null && (tokenExpirationDate.getTime() - now.getTime()) < 1000 * 60 * 30) {
            this.http
                .get(
                    this.r.API.sURL + 'refresh'
                )
                .subscribe(
                    (data: any) => {
                        if (data) {
                            this.updateToday();
                            this.r.sessionToken = data.token.access;
                            localStorage.setItem('iwiSessionToken', data.token.access);
                            this.r.sessionRefreshRequired = false;
                        } else {
                            this.logout(true);
                        }
                    },
                    (error: any) => {

                    },
                    () => {}
                );
        }
    }

    jwtCheck(myRawToken) {
        const decodedToken = jwtHelperService.decodeToken(myRawToken);
        const expirationDate = jwtHelperService.getTokenExpirationDate(myRawToken);
        const isExpired = jwtHelperService.isTokenExpired(myRawToken);
    }

    getTokenExpirationDate(): Date {
        if (jwtHelperService === null || jwtHelperService === undefined) {
            return null;
        } else {
            return jwtHelperService.getTokenExpirationDate(localStorage.getItem('iwiSessionToken'));
        }
    }

    getPermissions(callback ? ) {
        if (this.d.permissions === null || this.d.permissions === undefined) {
            this.d.permissions = [];
        }
        if (this.d.permissions.loading === true) {
            return;
        }
        this.d.permissions.loading = true;
        if (this.d.permissions.length < 1) {

            this.iwiu.startSpinner('Loading..');
            this.http
                .get(
                    this.r.API.URL + 'account/management/roles'
                )
                .subscribe(
                    (data: any) => {
                        this.d.permissions = data;
                        /*this.d.permissions.map((item) => {
                            let nameList = item.name.split('|');
                            item.title = nameList[0];
                            item.description = nameList[1];
                        });*/

                        //console.log(this.d.permissions);
                        this.iwiu.stopSpinner();
                        this.d.permissions.loading = false;
                        callback && callback();
                    },
                    (error: any) => {
                        this.d.permissions.loading = false;
                        this.iwiu.stopSpinner();
                        this.iwiu.errorHandling('Error', 'Get data error', error);
                    }
                );
        } else {
            this.d.permissions.loading = false;
            callback && callback();
        }
    }

    checkSession() {
        // 10 * 1000 = 10 sec
        this.sessionInterval = setInterval(() => {
            this.updateToday();

            const tokenExpirationDate = this.getTokenExpirationDate();
            if (tokenExpirationDate !== null) {
                //console.log(moment(tokenExpirationDate).fromNow());
                let minutes = moment(tokenExpirationDate).fromNow();
                if (minutes === 'in 15 minutes') {
                    this.r.sessionRefreshRequired = true;
                    this.sessionTimeOutToast(minutes);
                }
                //if (minutes === 'in a minute' || minutes === 'in a few seconds') {
                if (minutes === 'in a minute' || minutes === 'in 2 minutes' || minutes === 'in 3 minutes') {
                    this.sessionTimeOutToastFixed(minutes);
                }
                if (minutes.indexOf('ago') > -1) {
                    this.logout(true);
                }
            }

        }, 60 * 1000);
    }

    async sessionTimeOutToast(minutes: number | string) {
        // close toaster
        try {
            const toastElement = await this.toastController.getTop();
            if (toastElement) {
                toastElement.dismiss();
            }
        } catch (error) {}

        const toast = await this.toastController.create({
            message: 'Your session will expire ' + minutes + '.\nWould you like to continue?',
            duration: 10000,
            keyboardClose: true,
            color: 'secondary',
            position: 'middle',
            cssClass: 'toast-transparent',
            buttons: [{
                side: 'end',
                text: 'YES',
                role: 'submit',
                handler: () => {
                    this.refreshToken();
                }
            }]
        });
        toast.present();
    }

    async sessionTimeOutToastFixed(minutes: number | string) {
        // close toaster
        if (this.toastFixed !== null && this.toastFixed !== undefined) {
            this.toastFixed.dismiss();
            this.toastFixed = null;
        }
        try {
            const toastElement = await this.toastController.getTop();
            if (toastElement) {
                toastElement.dismiss();
            }
        } catch (error) {}

        this.toastFixed = await this.toastController.create({
            //header: 'Session timeout soon',
            message: 'Your session will expire ' + minutes + '.\nWould you like to continue?',
            duration: 60000,
            keyboardClose: true,
            color: 'secondary',
            position: 'bottom',
            buttons: [{
                side: 'end',
                text: 'YES',
                role: 'submit',
                handler: () => {
                    //console.log('Refresh clicked');
                    this.refreshToken();
                }
            }, {
                icon: 'close-circle-outline',
                role: 'cancel',
                handler: () => {
                    //console.log('Close clicked');
                }
            }]
        });

        this.toastFixed.present();
    }

    toggleNotification(show: number, card: string, $event ? ) {
        if ($event !== undefined && $event !== null) {
            $event.preventDefault();
        }
        this.r.notifications[card] = show;
        localStorage.setItem('iwi-' + this.r.userData.mrn, JSON.stringify(this.r.notifications));
    }

    registerDeviceForPush() {
        if (this.platform.is('cordova')) {
            this.Apperyio.push.init();

            console.log('platform.is cordova');

            this.d.uuid = (window as any).device.uuid;
            this.d.deviceType = (window as any).device.platform;
        }
    }

    sendPushMessage(callback ? ) {
        if ((!this.r.message.push.usernames || !this.r.message.push.title || !this.r.message.push.message) && !this.r.testMode) return;
        //targetList = [this.user.person.email]
        this.iwiu.startSpinner('Sending..');

        const data = {
            title: this.r.message.push.title || 'TestPush',
            body: this.r.message.push.message || 'Test Push Message to myself',
            usernames: this.r.message.push.usernames || [this.r.userData.username],
            type: 'push'
        }

        this.http
            .put(
                this.r.API.URL + 'message',
                data
            ).subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();

                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Send message failed', error, callback && callback());

                }
            );
    }

    startCheckIn(user, callback ? ) {
        this.iwiu.initConfirmation('Check-in Agent',
            'This action will unlock agent\'s operations till tomorrow',
            '<br> Current balance: ' + this.iwiu.currencyPipeTransform(user.balance.balance) +
            '<br> Cash collected: ' + this.iwiu.currencyPipeTransform(user.balance.cash) +
            '<br> Commission earned: ' + this.iwiu.currencyPipeTransform(user.balance.commission) +
            '<br> <br>' +
            '<b>' + (user.checkInAmount >= 0 ?
                'Agent cashout to manager ' +
                this.iwiu.currencyPipeTransform(user.checkInAmount) :
                'Manager own to agent ' +
                this.iwiu.currencyPipeTransform(-user.checkInAmount)) + '</b>',
            null, [{
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                    //no action just close alert
                }
            }, {
                text: 'Check-in',
                role: 'submit',
                cssClass: 'primary',
                handler: () => {
                    this.checkIn(user, callback);
                }
            }])
    }

    checkIn(user, callback ? ) {
        this.iwiu.startSpinner('Check-in processing..');

        const data = {
            id: user.id
        };

        this.http
            .post(
                this.r.API.URL + 'check-in',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();

                    callback && callback(data);

                    this.iwiu.initAlert('Agent Check-in', '', 'Agent successfully Checked-in');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();

                    this.iwiu.errorHandling('Error', 'Check-in error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }

    getHouses(callback ? ) {
        if (this.d.houses === null || this.d.houses === undefined) {

            this.http
                .get(
                    this.r.API.URL + 'houses/public/catalog'
                )
                .subscribe(
                    (data: any) => {
                        this.today = new Date();
                        const Nassau = momenttz.tz(this.today, "America/Nassau");
                        this.todayStr = moment(Nassau).format('MM-DD-YYYY');
                        const NassauLocal = moment(Nassau.format('MM-DD-yyyy HH:mm'))

                        //console.log(NassauLocal)
                        this.d.houses = data.map(house => {
                            house.datetime = moment(this.todayStr + ' ' + house.time);
                            house.inPast = NassauLocal.diff(house.datetime, 'minutes') > -30;
                            //console.log(house.inPast, house.datetime)
                            house.time = house.time.substring(0, 5);
                            return house;
                        });
                        //console.log(this.d.houses)
                        //callback && callback(this.d.houses);
                        callback && callback();
                    },
                    (error: any) => {
                        this.iwiu.errorHandling('Error', 'Get data error', error);
                    }
                );
        } else {
            callback && callback();
        }
    }

    getNassauTime(): string {
        return momenttz.tz(this.today, "America/Nassau").format('MM-DD-yyyy h:mm a');
    }

    printConnect(callback ? ) {
        /*
        qz.api.setSha256Type(data => sha256(data));
        qz.api.setPromiseType(resolver => new Promise(resolver));

        qz.websocket.connect()
            .then(qz.printers.getDefault)
            .then(printer => console.log("The default printer is: " + printer))
            .then(qz.websocket.disconnect)
            .catch(err => console.error(err));
        */

        /*
        REM https://qz.io/wiki/print-server
        REM Regenerate the certificate
        cd "%PROGRAMFILES%\QZ Tray\"
        java -jar qz-tray.jar certgen --hosts "localhost;appery.io;mgosupport.com"
        https://qz.io/wiki/signing-messages
        https://keystore-explorer.org/downloads.html

        */
        //if (!qz.websocket.isActive()) {
        //console.log('qz.websocket.isActive()', qz.websocket.isActive());
        //console.log('this.r.qzPrint.enabled', this.r.qzPrint.enabled);
        //console.log(qz.websocket.isActive());
        //console.log(this.r.qzPrint.enabled);
        //console.log(this.d.qzPrint.first === undefined);
        if (!qz.websocket.isActive() && this.r.qzPrint.enabled === true && this.d.qzPrint.first === undefined) {
            setTimeout(() => {
                //if (this.checkRole('admin', 'manager') && !this.r.isCordovaApp) {
                if (this.d.qzPrint.first === undefined) {
                    this.http
                        .get(
                            this.r.API.URL + 'print'
                        )
                        .subscribe(
                            (data: any) => {
                                data.key = '-----BEGIN PRIVATE KEY-----\n' +
                                    data.key +
                                    '-----END PRIVATE KEY-----\n';
                                data.cert = '-----BEGIN CERTIFICATE-----\n' +
                                    data.first +
                                    '-----END CERTIFICATE-----\n' +
                                    '--START INTERMEDIATE CERT--\n' +
                                    '-----BEGIN CERTIFICATE-----\n' +
                                    data.second +
                                    '-----END CERTIFICATE-----';
                                this.connectQZ(data);
                                console.log('QZ Tray connected (print ticket feature)');
                            },
                            (error: any) => {
                                //this.r.$ionicLoading.hide();
                                this.iwiu.errorHandling('Error', 'Get Certificates', error)
                            },
                            () => {
                                //console.log("final");
                            }
                        );

                } else {
                    console.log('qzPrint branch - not covered. call developers')
                    /*this.connectQZ((callback) => {
                        this.r.qzPrint(callback);
                    });*/
                }
                //}
            }, 2000);
        } else {
            callback && callback();
        }
    }

    connectQZ(security) {
        qz.security.setCertificatePromise((resolve, reject) => {
            resolve(security.cert);
        });

        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise((toSign) => {
            return (resolve, reject) => {
                try {
                    var pk = KEYUTIL.getKey(security.key);
                    var sig = new KJUR.crypto.Signature({
                        "alg": "SHA512withRSA"
                    }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
                    sig.init(pk);
                    sig.updateString(toSign);
                    var hex = sig.sign();
                    //console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
                    resolve(stob64(hextorstr(hex)));
                } catch (err) {
                    console.error(err);
                    //if (this.checkRole('manager') && !this.r.isCordovaApp) {
                    this.iwiu.initAlert('Printer connection', '', 'Setup QZ Tray failed<br>' + (err ? err : '') + '<br>Please, check printer ON and plugged in. QZ Tray app should be started.');
                    //}
                    reject(err);
                }
            };
        });

        //qz.api.showDebug(true);  // must be called prior to calling qz.websocket.connect()
        qz.websocket.connect({
                retries: 5,
                delay: 1
            })
            .then(qz.printers.getDefault)
            .then((printer) => {
                //console.log("Printer: " + printer);
                // Create a default config for the found printer
                this.d.qzPrint.config = qz.configs.create(printer, {
                    margins: 2,
                    colorType: 'grayscale',
                    jobName: 'iwi-ticket-receipt',
                    units: 'mm'
                });
                //TODO
                //callback && callback();
                //return qz.print(config, data);
            }).catch((err) => {
                //if (this.checkRole('manager') && !this.r.isCordovaApp) {
                this.iwiu.initAlert('Printer connection', '', 'Setup QZ Tray failed<br>' + (err ? err : '') + '<br>Please, check printer ON and plugged in. QZ Tray app should be started.');
                //}
                console.error(err);
            });
    };

    printTicketReceipt(userData, ticket, hideUpdatedBalance = false, printTwice ? ) {
        this.printConnect(() => {
            const dDateTimeFormat = 'DD-MMM-YYYY HH:mm:a';
            const saleAt = ticket.saleAt ? new Date(ticket.saleAt) : new Date();
            const drawnAt = ticket.drawnAt ? new Date(ticket.drawnAt) : new Date();
            let betLines = '';
            ticket.betLines.forEach((betLine) => {
                betLines = betLines +
                    '  <tr>' +
                    '    <td>' + betLine.numbers.length + ' Ball  ' + betLine.houseCode + '   ' + betLine.numbers + ' ' + betLine.type.substring(0, 3) + '</td>' +
                    '    <td style="text-align: right;">' + this.iwiu.currencyPipeTransform(betLine.amount) + '</td>' +
                    '  </tr>';

            })

            const receipt =
                '<b>iWin Games</b></br>' +
                '<b>address address</b></br>' +
                '<b>(242)000-1234</b></br>' +
                '<hr>' +
                '<table style="width:70%">' +
                '  <tr>' +
                '    <td>Sold by:</td>' +
                '    <td>' + userData.username + '</td>' +
                '  </tr>' +
                '  <tr>' +
                '    <td>Sale Date:</td>' +
                '    <td>' + moment(saleAt).format(dDateTimeFormat) + '</td>' +
                '  </tr>' +
                '  <tr>' +
                '    <td>Ticket:</td>' +
                '    <td>' + this.iwiu.ticketPipeTransform(ticket.number) + '</td>' +
                '  </tr>' +
                '  <tr>' +
                '    <td>Draw Date:</td>' +
                '    <td>' + moment(drawnAt).format(dDateTimeFormat) + '</td>' +
                '  </tr>' +
                '</table>' +
                '<hr>' +
                '<table style="width:70%">' +
                betLines +
                '  <tr>' +
                '    <td>Total:</td>' +
                //'    <td> </td>' +
                //'    <td> </td>' +
                '    <td style="text-align: right;">' + this.iwiu.currencyPipeTransform(ticket.totalAmount) + '</td>' +
                '  </tr>' +
                (hideUpdatedBalance === true ? '' :
                    '  <tr>' +
                    '    <td>Current user:</td>' +
                    '    <td>' + userData.username + '</td>' +
                    '  </tr>') +
                '</table>' +
                /*'<table style="width:70%">' +
                '  <tr>' +
                '    <td>Signatures</td>' +
                '    <td></td>' +
                '  </tr>' +
                '  <tr>' +
                '    <td>Merchant</td>' +
                '    <td>________________________</td>' +
                '  </tr>' +
                '  <tr>' +
                '    <td></br></br></td>' +
                '    <td></td>' +
                '  </tr>' +
                '  <tr>' +
                '    <td>Cashier</td>' +
                '    <td>________________________</td>' +
                '  </tr>' +
                '</table>' +*/
                '<hr>' +
                '<i>Thanks for using iWin Games</i></br>';
            //receipt = receipt + '</br></br><hr></br></br>' + receipt;
            var data = [{
                type: 'html',
                format: 'plain', //'file' or 'plain' if the data is raw HTML
                data: receipt
            }];
            if (this.r.qzPrint.enabled === true) {
                qz.print(this.d.qzPrint.config, data)
                    .then(() => {
                        /*if (printTwice !== false) {
                            qz.print(this.d.qzPrint.config, data)
                                .then(() => {
                                    //callback && callback();
                                })
                                .catch((e) => {
                                    console.error(e);
                                });
                        }*/
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            } else {
                //callback && callback();
            }
        });
    };
}

/*
    Service class should be exported as ExportedClass
*/
export {
    IwiService as ExportedClass
};