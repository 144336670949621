import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    ModalController
} from '@ionic/angular';
import {
    NavParams
} from '@ionic/angular';
import {
    HttpParams
} from '@angular/common/http';
import {
    HttpHeaders
} from '@angular/common/http';
import {
    ExportedClass as IwiService
} from '../scripts/custom/IwiService';
import {
    ExportedClass as IwiUtilService
} from '../scripts/custom/IwiUtilService';
import {
    ExportedClass as IwiModalService
} from '../scripts/custom/IwiModalService';
import * as moment from 'moment';
import {
    $aio_empty_object
} from '../scripts/interfaces';
@Component({
    templateUrl: 'ModManageClient.html',
    selector: 'page-mod-manage-client',
    styleUrls: ['ModManageClient.css', 'ModManageClient.scss']
})
export class ModManageClient {
    public title: string;
    public user: any;
    public mode: string;
    public card: any;
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    public mappingData: any = {
        "j_990__disabled": true,
    };
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeModal(newMode?): any {
        this.modalCtrl.dismiss({
            mode: newMode || this.mode || 'back',
            item: this.user
        });
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public modalCtrl: ModalController, public navParams: NavParams, public iwi: IwiService, public iwiu: IwiUtilService, public tepm: IwiModalService) {
        this.card = {
            perm: {
                expanded: false
            },
            docs: {
                expanded: false
            },
            surveys: {
                expanded: false
            },
            agent: {
                expanded: false
            }
        };
        this.aioChangeDetector = this.$aio_changeDetector;
        this.user = this.navParams.get('item');
        this.title = this.user.username;
        if (this.checkRoleForEditedUser('agent')) {
            this.user.checkInAmount = +(this.user.balance.cash - this.user.balance.commission);
        }
    }
    save(): any {
        this.iwiu.startSpinner('Updating..');
        const data = this.user;
        this.iwi.http
            .post(
                this.iwi.r.API.URL + 'account/management/update',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Update error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    setPermission(role, index): any {
        const idx = this.user.roles.indexOf(role.name);
        if (idx !== -1) {
            this.user.roles.splice(idx, 1);
        } else {
            this.user.roles.push(role.name);
        }
    }
    startSendMessage(): any {
        this.iwi.r.message.push = {};
        this.iwiu.initNotes(
            'Send Push Notification',
            'Enter message title and body below', [{
                text: 'Cancel',
                handler: () => {}
            }, {
                text: 'Send',
                handler: (data) => {
                    this.iwi.r.message.push = {
                        title: data.title,
                        message: data.message,
                        usernames: [this.user.username]
                    };
                    this.iwi.sendPushMessage(() => {
                        this.closeModal();
                    });
                }
            }], [{
                name: 'title',
                value: this.iwi.r.message.push.title,
                type: 'text',
                placeholder: 'Enter title'
            }, {
                name: 'message',
                value: this.iwi.r.message.push.message,
                type: 'text',
                placeholder: 'Enter message'
            }]
        );
    }
    approve(): any {
        this.iwiu.startSpinner('Approving..');
        const data = {
            id: this.user.id
        };
        this.iwi.http
            .put(
                this.iwi.r.API.URL + 'account/management/approve',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.user = data;
                    this.mode = 'update';
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Update error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    startDisable(): any {
        if (!this.user.disabled) {
            this.iwiu.initConfirmation('Disable User', 'This action will lock any user\'s finansial oprations in the system, you can enable user back later', 'Do you really want to Disable User?', null, [{
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                    //no action just close alert
                }
            }, {
                text: 'Disable',
                role: 'submit',
                cssClass: 'primary',
                handler: () => {
                    this.disable();
                }
            }])
        } else {
            this.disable();
        }
    }
    disable(): any {
        this.iwiu.startSpinner(this.user.disabled? 'Enabling..': 'Disabling..');
        const data = {
            id: this.user.id,
            reason: 'Temporary ban'
        };
        this.iwi.http
            .put(
                this.iwi.r.API.URL + 'account/management/' + (this.user.disabled? 'enable': 'disable'),
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.user = data;
                    this.iwiu.initAlert('User Management', '', 'User successfully ' + (this.user.disabled? 'Disabled': 'Enabled'));
                    this.mode = 'update';
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'Update error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    checkRoleForEditedUser(arg?): any {
        var args = arguments;
        if (!this.user || !this.user.roles) {
            return false;
        }
        for (var a in args) {
            if (this.user.roles.indexOf(args[a].toUpperCase()) >= 0) {
                return true;
            }
        }
        //console.log($scope.r.userData.userRoles, arguments);
        return false;
    }
    startCheckIn(): any {
        this.iwi.startCheckIn(this.user, (data) => {
            this.user = data;
            this.user.checkInAmount = +(this.user.balance.cash - this.user.balance.commission);
        });
    }
    startTopUp(): any {
        this.iwi.r.topUpAmount = 1;
        this.iwiu.initNotes('User TopUp', 'Enter TopUp Amount', [{
            text: 'Cancel',
            handler: () => {}
        }, {
            text: 'TopUp',
            handler: (data) => {
                this.iwi.r.topUpAmount = data.topUpAmount;
                this.topUp();
            }
        }], [{
            name: 'topUpAmount',
            value: this.iwi.r.topUpAmount,
            type: 'number',
            placeholder: 'Enter amount'
        }]);
    }
    topUp(): any {
        this.iwiu.startSpinner('TopUp processing..');
        const data = {
            id: this.user.id,
            amount: this.iwi.r.topUpAmount
        };
        this.iwi.http
            .post(
                this.iwi.r.API.URL + 'money/topup',
                data
            )
            .subscribe(
                (data: any) => {
                    this.iwiu.stopSpinner();
                    this.user.balance.balance = +data.to.balance;
                    this.mode = 'update';
                    this.iwiu.initAlert('User TopUp', '', 'User\'s balance updated successfully');
                    //this.closeModal('update');
                },
                (error: any) => {
                    this.iwiu.stopSpinner();
                    this.iwiu.errorHandling('Error', 'TopUp error', error);
                },
                () => {
                    //console.log("final");
                }
            );
    }
    openHistory(): any {
        this.closeModal();
        this.iwi.r.history.user = this.user;
    }
}